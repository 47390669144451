import "./App.css";
import "./res/stylesheet.css";
import tmlblogo from "./res/tmlb.png";
import oallogo from "./res/oal.png";
import l68logo from "./res/l68.png";
import oalmlogo from './res/oalm.png';
import { Component } from "react";
import {
	Button,
	ButtonGroup,
	Callout,
	H3,
	H4,
	InputGroup,
	Spinner
} from "@blueprintjs/core";
import OalAuthBackend from './lib/oal-auth-backend';

const logos = {
	tmlblogo,
	oallogo,
	l68logo,
	oalmlogo
};

const companies = {
	oal: 'OAL AS',
	l68: 'Lørenveien 68',
	oalm: 'Frontir Media & Technology Group AS',
	fmtg: 'Frontir Media & Technology Group AS',
	tmlb: 'Trippel-M Levende Bilder AS',
	oss: 'Oslo Streamingsenter AS',
	tmcv: 'Trippel-M Connected Venues AS',
	opti: 'Optilux AS'
};

class App extends Component {
	constructor() {
		super();

		this.search = new URLSearchParams(window.location.search);

		const c = this.search.get('c');
		let company = (c && companies[c] !== undefined) ? c : 'oalm';

		const reset = this.search.get('reset');
		const user = this.search.get('username') ?? undefined;

		this.state = {
			companyLabel: companies[company],
			companyLogo: logos[company + 'logo'],
			selectedMethod: "customer",
			supportedMethods: [],
			returnUrl: this.search.get('return') || 'https://dashboard.oal.no/',
			stage: reset ? 5 : 1,
			informationMessage: '',
			loginData: {
				user
			},
			resetKey: reset,
			submitting: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		// Only accept numbers and + in the phone number field (no spaces or letters etc)
		if (prevState.loginData.user !== this.state.loginData.user) {
			const newUser = this.state.loginData.user.replace(/[^0-9+]/g, '');

			if (newUser !== this.state.loginData.user) {
				this.setState({
					loginData: {
						...this.state.loginData,
						user: newUser
					}
				});
			}
		}
	}

	validate() {
		if (
			this.state.loginData.user !== undefined &&
			this.state.loginData.user.match(/^(\+[0-9]{10,}|[94][0-9]{7})$/)
		) {
			return true
		} else {
			return false
		}
	}

	reset() {
		this.setState({
			passwordChanged: false,
			errorMessage: null,
			informationMessage: null,
			submitting: false,
			passwordValid: false,
		});
		this.validate();
	}

	validatePassword() {
		const { newPassword, repeatPassword } = this.state.loginData;

			if (
			newPassword === repeatPassword &&
			newPassword !== '' &&
			newPassword &&
			newPassword.length > 6
		) {
			this.setState({ passwordValid: true });
		} else {
			this.setState({ passwordValid: false });
		}
	}

	async submitPassword(e) {
		e.preventDefault();

		const newPassword = this.state.loginData.newPassword;
		let user = this.state.loginData.user;

		user = this.washNumber();

		if (this.state.passwordValid) {
			try {
				await OalAuthBackend.setPassword(user, this.state.resetKey, newPassword);

				this.setState({
					informationMessage: 'Passordet ditt er nå forandret! Vennligst prøv å logg inn på nytt med ditt nye passord.',
					passwordChanged: true
				});
			} catch (e) {
				if (e.name === 'invalid_reset') {
					this.setState({
						informationMessage: '',
						errorMessage: 'Reset-koden er ugyldig eller har gått ut på tid. Vennligst benytt deg av "Glemt passord" knappen på nytt.'
					})
				} else if (e.name === 'invalid_password') {
					this.setState({
						informationMessage: '',
						errorMessage: 'Passordet oppfyllte ikke alle våre krav, prøv igjen med et annet passord.'
					})
				} else {
					this.setState({
						informationMessage: '',
						errorMessage: 'En internfeil oppstod, vennligst kontakt alpha@trippelm.no for hjelp.'
					})
				}
			}
		}
	}

	washNumber(number) {
		return this.state.loginData.user.replace(/^\+47/, '');
	}

	async resetPassword() {
		this.setState({
			stage: 4,
			submitting: false
		});

		await OalAuthBackend.resetPassword(this.washNumber());
	}

	async stage1() {
		this.setState({
			submitting: true,
			errorMessage: null
		})

		let info;
		const username = this.washNumber();

		try {
			// test
			info = await OalAuthBackend.checkUser(username);
		} catch (e) {
			console.error(e);
			this.setState({
				errorMessage: 'En intern feil oppstod. Vennligst prøv igjen.',
				submitting: false
			})
			return;
		}

		const supportedMethods = [];

		if (info.ADUser) {
			supportedMethods.push('internal')
		}
		if (info.ossCustomer || info.tmlbCustomer) {
			supportedMethods.push('customer')
		}

		if (supportedMethods.length) {
			if (supportedMethods.length === 1) {
				this.setState({
					submitting: false,
					selectedMethod: supportedMethods[0],
					stage: 2	
				})
			} else {
				this.setState({
					submitting: false,
					stage: 2
				})
			}
		} else {
			this.setState({
				submitting: false,
				errorMessage: 'Ukjent brukernavn'
			})
	
		}
	}

	async stage2() {
		this.setState({
			submitting: true,
			errorMessage: null
		})
		const username = this.washNumber();

		if (this.state.selectedMethod === 'internal') {
			let result;
			try {
				result = await OalAuthBackend.authenticate(username, this.state.loginData.password, this.state.selectedMethod);
				if (result === false) {
					this.setState({
						submitting: false,
						stage: 2,
						loginData: {
							...this.state.loginData,
							password: ''
						},
						errorMessage: 'Feil passord, vennligst prøv igjen'
					});
					return;
				} else {
					this.setState({
						submitting: false,
						stage: 3,
						loginData: {
							...this.state.loginData,
							password: ''
						},
						errorMessage: ''
					}, () => {
						const newUrl = new URL(this.state.returnUrl);
						newUrl.searchParams.set('logintoken', result.logintoken);
						window.location.href = newUrl.toString();
					});
				}
			} catch (e) {
				this.setState({
					submitting: false,
					stage: 2,
					loginData: {
						...this.state.loginData,
						password: ''
					},
					errorMessage: 'Internfeil, vennligst prøv igjen'
				});
				return;
			}
		} else {
			// TODO: Fix login for customers

			this.setState({
				submitting: false,
				stage: 1,
				loginData: {
					...this.state.loginData,
					password: ''
				},
				errorMessage: 'Ikke implementert'
			})

		}
	}

	async submitLogin(e) {
		e.preventDefault();

		if (this.state.stage === 1) {
			this.stage1();
		}
		else if (this.state.stage === 2) {
			this.stage2();
		}
	}

	stage1Render() {
		const loginValid = this.validate();

		return (
			<form
				onSubmit={(e) => this.submitLogin(e)}
			>
				<div>
					{this.state.smsCode && (
						<Callout
							style={{ marginBottom: 10 }}
							intent="success"
							title="Engangskode på SMS"
						>
							Det er blitt sendt en kode på sms til ditt mobilnummer. Vennligst fyll inn koden du mottok, i feltet nedenfor.
						</Callout>
					)}
					{this.state.errorMessage && (
						<Callout
							style={{ marginBottom: 10 }}
							intent="warning"
							title="Feilmelding"
						>
							{this.state.errorMessage}
						</Callout>
					)}
					<div>
						<InputGroup
							autoFocus
							placeholder="Ditt mobilnummer"
							autoComplete="username"
							intent={ loginValid ? "success" : null}
							value={this.state.loginData.user || ""}
							onChange={(e) => {
								this.setState(
									{
										loginData: {
											...this.state.loginData,
											user: e.target.value,
										},
									},
									() => this.validate()
								);
							}}
							large
						/>
					</div>
				</div>

				<Button
					type="submit" 
					intent="success"
					loading={this.state.submitting}
					disabled={!loginValid}
					style={{ marginTop: 10 }}
					large
					fill
				>
					Gå videre
				</Button>
			</form>
		)
	}

	stage2Render() {
		const loginValid = this.validate();

		return (
			<div>
				{this.state.supportedMethods.length > 1 && (
					<ButtonGroup fill style={{ marginBottom: 20 }}>
						{this.state.supportedMethods.indexOf("customer") > -1 && (
							<Button
								icon="crown"
								onClick={(e) =>
									this.setState(
										{
											selectedMethod: "customer",
											loginData: {},
										}
									)
								}
								intent={
									this.state.selectedMethod === "customer"
										? "primary"
										: null
								}
								large
							>
								Kunde
							</Button>
						)}
						{this.state.supportedMethods.indexOf("internal") > -1 && (
							<Button
								icon="build"
								onClick={(e) =>
									this.setState(
										{
											selectedMethod: "internal",
										}
									)
								}
								intent={
									this.state.selectedMethod === "internal"
										? "primary"
										: null
								}
								large
							>
								Intern/Frilanser
							</Button>
						)}
					</ButtonGroup>
				)}
				<form
					onSubmit={(e) => this.submitLogin(e)}
				>
					<div>
						{ /* 1password osv liker dette, for å hente brukernavnet sammen med passordet */ }
						<InputGroup style={{display:'none'}} readOnly autoComplete="username" value={this.state.loginData.user} />

						{this.state.smsCode && (
							<Callout
								style={{ marginBottom: 10 }}
								intent="success"
								title="Engangskode på SMS"
							>
								Det er blitt sendt en kode på sms til ditt mobilnummer. Vennligst fyll inn koden du mottok, i feltet nedenfor.
							</Callout>
						)}
						{this.state.errorMessage && (
							<Callout
								style={{ marginBottom: 10 }}
								intent="warning"
								title="Feilmelding"
							>
								{this.state.errorMessage}
							</Callout>
						)}
						{this.state.selectedMethod === "customer" && (
							<div>
								<InputGroup
									autoFocus
									placeholder="SMS Kode"
									autoComplete="current-password"
									intent={ loginValid ? "success" : null}
									value={this.state.loginData.password || ""}
									onChange={(e) => {
										this.setState(
											{
												loginData: {
													...this.state.loginData,
													password: e.target.value,
												},
											},
											() => this.validate()
										);
									}}
									large
								/>
							</div>
						)}
						{this.state.selectedMethod === "internal" && (
							<div>
								<InputGroup
									autoFocus
									type="password"
									placeholder="Ditt passord"
									autoComplete="current-password"
									intent={ loginValid ? "success" : null}
									value={this.state.loginData.password || ""}
									onChange={(e) => {
										this.setState(
											{
												loginData: {
													...this.state.loginData,
													password: e.target.value,
												},
											},
											() => this.validate()
										);
									}}
									large
								/>
							</div>
						)}
					</div>

					<Button
						type="submit" 
						intent="success"
						loading={this.state.submitting}
						disabled={!loginValid}
						style={{ marginTop: 10 }}
						large
					>
						Logg inn
					</Button>
					<Button
						type="button" 
						intent=""
						style={{ marginTop: 10, marginLeft: 10 }}
						
						onClick={() => this.resetPassword()}
					>
						Glemt passord
					</Button>
					<Button
						type="button" 
						intent=""
						style={{ marginTop: 10, marginLeft: 10 }}
						onClick={() => this.setState({ stage: 1 }, () => this.reset())}
					>
						Avbryt
					</Button>
				</form>
			</div>
		)
	}

	render() {
		const loginValid = this.validate();

		return (
			<div className="App">
				<header className="App-header">
					<H3
						style={{ color: "white", marginBottom: 25, fontFamily: "Camber" }}
					>
						{/*this.state.companyLabel*/}	
					</H3>
					<div className="box">
						<div className="logocontainer">
							<img src={this.state.companyLogo} alt="logo" style={{ width: '100%' }} />
						</div>

						{this.state.stage === 1 && this.stage1Render()}
						{this.state.stage === 2 && this.stage2Render()}

						{this.state.stage === 3 && (
							<div>
								<Spinner size={50} />
								<H4 className="mt-4" style={{ textAlign: 'center', marginTop: '1em' }}>
									Sender deg videre...
								</H4>
							</div>
						)}

						{this.state.stage === 4 && (
							<div>
								<H4 className="mt-4" style={{ textAlign: 'center', marginTop: '1em' }}>
									Du vil få en melding på mobilen.<br />
									Følg beskjedene der før du fortsetter her.
								</H4>
								<Button
									type="button" 
									intent="primary"
									style={{ marginTop: 10, marginLeft: 10 }}
									onClick={() => this.setState({ stage: 1, submitting: false })}
								>
									Tilbake
								</Button>
							</div>
						)}

						{this.state.stage === 5 && (
							<form onSubmit={(e) => this.submitPassword(e)}>
								{ /* 1password osv liker dette, for å hente brukernavnet sammen med passordet */ }
								<InputGroup style={{display:'none'}} className="visually-hidden" readOnly autoComplete="username" value={this.state.loginData.user} />

								{this.state.informationMessage && (
									<Callout
										style={{ marginBottom: 10 }}
										intent="success"
										title="Informasjon"
									>
										{this.state.informationMessage}
									</Callout>
								)}
								{this.state.errorMessage && (
									<Callout
										style={{ marginBottom: 10 }}
										intent="warning"
										title="Feilmelding"
									>
										{this.state.errorMessage}
									</Callout>
								)}

								{!this.state.informationMessage && (
									<>
										<H4 className="mt-4" style={{ textAlign: 'center', marginTop: '1em' }}>
											Skriv inn nytt passord, minst 8 tegn, store og små bokstaver, og minst et siffer
										</H4>
										
										<InputGroup
											autoFocus
											type="password"
											placeholder="Nytt passord"
											autoComplete="new-password"
											intent={ loginValid ? "success" : null}
											value={this.state.loginData.newPassword || ""}
											onChange={(e) => {
												this.setState(
													{
														loginData: {
															...this.state.loginData,
															newPassword: e.target.value,
														},
													},
													() => this.validatePassword()
												);
											}}
											large
										/>
										<InputGroup
											style={{ marginTop: 10 }}
											type="password"
											placeholder="Gjenta nytt passord"
											autoComplete="new-password"
											intent={ loginValid ? "success" : null}
											value={this.state.loginData.repeatPassword || ""}
											onChange={(e) => {
												this.setState(
													{
														loginData: {
															...this.state.loginData,
															repeatPassword: e.target.value,
														},
													},
													() => this.validatePassword()
												);
											}}
											large
										/>
										<Button
											type="submit" 
											intent="success"
											loading={this.state.submitting}
											disabled={!this.state.passwordValid}
											style={{ marginTop: 10 }}
											large
										>
											Sett nytt passord
										</Button>
									</>
								)}
								<Button
									type="button" 
									intent={this.state.passwordChanged ? 'success' : ""}
									style={{ marginTop: 10, marginLeft: 10 }}
									onClick={() => this.setState({ stage: 1 }, () => this.reset())}
								>
									{this.state.passwordChanged ? 'Tilbake' : 'Avbryt'}
								</Button>
							</form>
						)}
					</div>
				</header>
			</div>
		);
	}
}

export default App;
