import axios from "axios";
import crypto from 'crypto';
import jose from 'node-jose';

const algorithm = 'aes-256-ctr';
const SHARED_SECRET = "QuN~lNatRRwQuN~o1BQVlNBQwo1XXatV"

const backend = axios.create({
	baseURL: process.env.NODE_ENV === 'production' ? '/api/v1' : 'https://127.0.0.1:5000/api/v1',
	timeout: 10000
});

export default class OalAuthBackend {
	static PUBLIC_KEY = '';
	static keystore;

	static async init() {
		try {
			this.PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
			MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAwek4mZq1OPlJBa4gasJW
			GqM6+yEMHON8hbeIc7GdsSuySn74BhK8h2buvaqbA7wbaxd4MJLnBzrDMWq4wJfj
			RWSeWbQJhnKuoTdQKO20/R8cEeU2TBFq8CJ04aDidV1RyM+ElfWuFZxkh2uEowv6
			Rw7jHzXZYRi8a6RAThYO1jivXxKQhPiQSk/D38M/6WmStRb7QpaXVu7sZWO+tQ1S
			Ai79EPbAcSz1lFDqtrR2xnYFUkXz/sVMkkhFqr/Urap2UIIRxo/wveOwuSNtc22p
			q7AJlBf4xrD4FOz/tud0obMdS4Spu6RH6u53nFYEwsnvckFJD1Co05S7CC10SzLV
			Bn1htJWqndhH9XwcfHPk/KGSx9+JS8G9pOmRo+TMfmK6hq4D4L8bpkf3XDZofQ+R
			o/A2d2TODRC8BLMZcm4GKRWn4PzqMd5XwHLKHPUpsGxBNl3+s3MSLSKpGTnyq1y4
			WiDKkRBo9SHp2JqZJ72NnZR6t3dog41njFE8PuxezZfyXoTeVKk0BRMYKdG5rcfH
			omJ32NcRVutuj+p3RnDIn7evpEcSdyXdHJ5MH3uH3SuqygSpEa6O0BL7Hvx8rE7E
			LbTy8Y8VNZgZas9DShXjbI8R8XTmBKm2VDpNfx4KGouCN7HQAhsQHZZb/mWVSes+
			Oau4gWDHXNtwGPeh15yu0a0CAwEAAQ==
			-----END PUBLIC KEY-----`;
			// Can be fetched from https://auth.oal.no/api/v1/auth/key manually, do not automate

			this.keystore = jose.JWK.createKeyStore();
			await this.keystore.add(this.PUBLIC_KEY, 'pem');
		} catch (e) {
			console.error('Could not retreive JWT public key from OAL API');
		}
	}

	static encrypt(data, salt) {
		const iv = crypto.randomBytes(16);
		const cipher = crypto.createCipheriv(algorithm, crypto.createHash('sha256').update(salt + SHARED_SECRET).digest(), iv);
		const content = Buffer.concat([cipher.update(data), cipher.final()]).toString('hex');

		return { content, iv: iv.toString('hex') };
	}

	static _handleException(e) {
		if (e.response && e.response.data && e.response.data.message) {
			const error = new Error('backend-error: ' + e.response.data.message);
			error.name = e.response.data.name;
			error.code = e.response.data.code;
			throw error;
		} else if (e.response && e.response.data && e.response.data.error) {
			const error = new Error('backend-error: ' + e.response.data.error);
			error.name = e.response.status;
			throw error;
		} else {
			throw e;
		}
	}

	static async _post(...args) {
		try {
			return await backend.post(...args);
		} catch (e) {
			OalAuthBackend._handleException(e);
		}
	}

	static async _get(...args) {
		try {
			return await backend.get(...args);
		} catch (e) {
			OalAuthBackend._handleException(e);
		}
	}

	static async checkUser(username) {
		const result = await OalAuthBackend._get('/auth/check', {
			params: {
				username
			}
		});

		return result.data;
	}

	static async authSalt(username, method) {
		const result = await OalAuthBackend._post('/auth/salt', {
			username,
			method
		});

		return result.data.salt;
	}	

	static async authLogin(username, salt, password, method) {
		const { content, iv } = OalAuthBackend.encrypt(password, salt);

		const result = await OalAuthBackend._post('/auth/login', {
			username,
			content,
			iv,
			method
		});

		return result.data;
	}

	static async authenticate(username, password, method) {
		let salt, jwtData, logintoken;
		try {
			salt = await OalAuthBackend.authSalt(username, method);
			const result = await OalAuthBackend.authLogin(username, salt, password, method);
			jwtData = result.jwt;
			logintoken = result.logintoken;
		} catch (e) {
			if (e.name === 'login_not_ok') {
				return false;
			} else {
				throw e;
			}
		}

		try {
			const jwt = jose.JWS.createVerify(this.keystore, { algorithms: [ 'RS256' ] });
			await jwt.verify(jwtData);
		} catch (e) {
			console.error(e);
			throw new Error('Key verification error, server compromised?');
		}
		
		return { jwt: jwtData, logintoken };
	}

	static async setPassword(username, reset, password) {
		const salt = await OalAuthBackend.authSalt(username, 'internal');

		const { content, iv } = OalAuthBackend.encrypt(password, salt);

		return OalAuthBackend._post('/auth/setpassword', {
			username,
			reset,
			content,
			iv
		});
	}

	static async resetPassword(username) {
		return OalAuthBackend._post('/auth/resetpassword', { username });
	}

}

OalAuthBackend.init();